import Header from '../Header'
import Footer from '../Footer'
import Head from '../Head'
import * as common from '../../common/commonFunction'
import cookie from '../../common/cookieManager'
import { setCookie, getCookie } from 'cookies-next';
import { useSpring, animated } from 'react-spring';
import { useEffect, useState } from 'react';
import $ from 'jquery';

const Modal = (prop) => {

    return (
        <>
            <div className="container w-100 text-center m-0" onClick={() => {$('#modalPopup').css('display', 'none');}}>
                <div className="modal" id="modalPopup">
                    <div className="modal_body w-70">
                    </div>
                </div>
            </div >
        </>
    )
}
export default Modal;