import Header from '../components/Header'
import Footer from '../components/Footer'
import Head from '../components/Head'
import Modal from '../components/modal/Modal'
import * as common from '../common/commonFunction'
import commonSession from "../common/commonSession"
import { useSpring, animated } from 'react-spring';
import { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from "axios";
import { P01_01_01_NAME, P01_01_02_NAME, LOGIN_ALERT1 } from "../lib/global_variable"
import { isMobile } from "react-device-detect";

// Import Swiper React components
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import e from 'cors';

// 메인 페이지 = 펀딩 메인 페이지 일치 처리 
const Home = () => {

    var sessionInfo = commonSession.getSession()
    var auth = "Bearer " + sessionInfo.token;
    const [resultList, setResultList] = useState([]);
    const [LdList, setLdList] = useState([]);
    const [itemInfo, setItemInfo] = useState([]);
    const [reload, setReload] = useState(false);
    const [resultListCnt, setResultListCnt] = useState(0); //all polling list
    const ranking = [1, 2, 3]; //ranking 정보 

    useEffect(() => {

        // 카테고리 기준으로 투표결과 리스트 가져오기
        const option = {
            url: "/project/pollingResult/category?categoryID=P01_01_02",
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                "Authorization": auth
            }
        }

        axios(option)
            .then(response => {
                console.log(response);
                if (response.data.codeMsg != 'NO_DATA_ERROR' && response.data.codeMsg == 'SUCCESS') {
                    setResultList(response.data.data);
                    setResultListCnt(response.data.data.length)
                }
            });


        // 리더보드 데이터 가져오기 처리 
        const option1 = {
            url: "/pay/leaderboard",
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                "Authorization": auth
            }
        }

        axios(option1)
            .then(response => {
                console.log(response.data.data);
                if (response.data.data) {
                    setLdList(response.data.data);
                }
            });

    }, []);

    useEffect(() => {

        if (!reload) {

            $.each(resultList, async function (i, e) {

                const option2 = {
                    url: "/funding?pollingResultId=" + e.pollingResultId,
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        "Authorization": auth
                    }
                }

                await axios(option2)
                    .then(async result => {
                        e.fundingData = result.data.data

                        const startAt = new Date(result.data.data.StartAt);
                        const endAt = new Date(result.data.data.EndAt);
                        const today = new Date();
                        
                        // 펀딩 진행 퍼센트 계산 
                        e.percentage = 0;
                        e.perClass = "percent-4"
                        e.dayClass = "percent-3"

                        if (startAt <= today && endAt >= today) {
                            e.showBtn = true;
                            e.showBtnText = '프로젝트 더 알아보기'
                        } else {
                            e.showBtn = true;

                            if (startAt > today) {
                                e.showBtnText = '퍼블릭 세일 오픈 전';
                            } else {
                                e.showBtnText = '퍼블릭 세일 마감완료';
                                e.perClass = "percent-1"
                            }
                        }


                        // 시작고ㅓㅏ 끝까지 몇일이 남았는지를 저장시킨다. 
                        e.leftDate = common.getDDay(result.data.data.EndAt);
                        e.comeDate = common.getDDay(result.data.data.StartAt);


                        if (result.data.extraData) {
                            e.percentage = (((result.data.extraData.totalTicket - result.data.extraData.remainTicket) / (result.data.extraData.totalTicket)) * 100).toFixed(2);

                            if (e.percentage >= 81) {
                                e.perClass = "percent-1"
                            } else if (e.percentage >= 61) {
                                e.perClass = "percent-2"
                            } 

                            if (e.dayClass <= -15) {
                                e.dayClass = "percent-1"
                            } else if (e.percentage <= -10) {
                                e.dayClass = "percent-2"
                            } else if (e.percentage <= -5) {
                                e.dayClass = "percent-4"
                            } 
                        }

                        if (resultList.length < resultListCnt) {
                            setResultList([...resultList, e])
                        }

                        if (resultListCnt && i == (resultListCnt - 1)) {
                            setReload(true)
                        }

                    });

            })

        }

        console.log(resultList);

    }, [resultList])

    // 상세 페이지 노출
    async function showDetail(id) {

        resetModal();
        await getItemInfo(id, 'detail');

    }

    // 펀딩페이지로 이동 처리 
    async function buyFundingTicket(id) {
        resetModal();

        await getItemInfo(id, 'funding');

        // 해당 펀딩 페이지로 이동 처리 추후 추가 필요
    }

    async function getItemInfo(id, type) {
        // 아이디 기준으로 데이터 정보 가져오기

        if (id == 'none') {
            var html = '<p class="title">저작권 구매일정은 추후 오픈 예정입니다.</p><img src="/img/time_icon.svg"></img>';
            showModal(html)
            return false;
        }

        const option = {
            url: "/project/pollingResult/pollingResultId?pollingResultId=" + id,
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                "Authorization": auth
            }
        }

        await axios(option)
            .then(response => {
                console.log(response);
                if (response.data.codeMsg != 'NO_DATA_ERROR' && response.data.codeMsg == 'SUCCESS') {
                    setItemInfo(response.data.data);

                    if (type == 'funding') {
                        //펀딩 마감 정보 노출
                        window.location.href = '/funding/View?pollingResultId=' + id
                    } else {

                        var html = '<p class="title">' + response.data.data.synopsisTitle + '</p><p class="writer-name">' + response.data.data.writerName + '</p><p class="content">' + response.data.data.description + '</p><img src="/img/explain_icon.svg"></img>';
                        showModal(html)

                    }
                }
            });
    }

    function resetModal() {
        $('#modalPopup').find('.modal_body').html('');
    }

    function showModal(html) {
        $('#modalPopup').find('.modal_body').append(html);
        $('#modalPopup').show();
    }

    const setActiveTab = (activeTab, e) => {
        $('.funding-list-box').addClass('d-none');
        $('.fd-tab-item').removeClass('active');
        $('.fd-tab-' + activeTab).addClass('active');
        switch (activeTab) {
            case 'ing':
                // 펀딩중
                $('#ing-prjt').removeClass('d-none');
                return true;
            case 'soon':
                // 펀딩예정
                $('#soon-prjt').removeClass('d-none');
                return true;
            case 'done':
                // 펀딩완료
                $('#achieved-prjt').removeClass('d-none');
                return true;
            default:
                // 전체
                $('.funding-list-box').removeClass('d-none');
                return true;
        }
    };

    const showMoreLd = (e) => {
        console.log($('i.show-more').hasClass('active'));
        if ($('i.show-more').hasClass('active')) {
            // 리스트 하이딩
            $('.ld-brd-item').each(function (i, e) {
                if (i >= 3) {
                    $(e).addClass('d-none')
                }
            });

            $('i.show-more').removeClass('active');
            $('.ld-brd-list').removeClass('active')
        } else {
            // 리스트 노출 
            $('.ld-brd-item').removeClass('d-none');
            $('i.show-more').addClass('active');
            $('.ld-brd-list').addClass('active')
        }
    }

    // 모바일에 따라 노출 처리 -- kjy
    if (isMobile) {
        return (<>
            <Head />
            <Header />
            <link rel='stylesheet' href='/css/funding.min.css' />
            <div className="funding title-box">
                <div className="m-banner">
                    <p className="section-title"><span className="c-red">HOT</span> 프로젝트</p>
                    <Swiper
                        modules={[Autoplay, Pagination, Navigation]}
                        pagination={{ clickable: true }}
                        slidesPerView={1}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false, // 사용자 상호작용시 슬라이더 일시 정지 비활성
                        }}
                        loop={true}
                        className='swiper-container funding-list'>
                       <SwiperSlide>
                            <img src="/img/renew-img/banner/web/banner_1.png"></img>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/img/renew-img/banner/web/banner_2.png"></img>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/img/renew-img/banner/web/banner_3.png"></img>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className="ld-brd">
                    <p className="section-title"><span className="c-purple">리더</span>보드</p>
                    <div className="ld-brd-list">
                        <ul>
                            {LdList && LdList.map((item, index) => {
                                return <li className={`ld-brd-item` + (index > 2 ? ` d-none` : ``)}>
                                    <img src={item.thumbnailLink ? item.thumbnailLink :`/img/renew-img/banner/web/ld_test_img.svg`} />
                                    <div className='ld-brd-content'>
                                        <p className="ld-brd-item-nick">{item ? item.nickName : 'XXXXXX'} 님 </p>
                                        <p className="ld-brd-item-title">[{item ? item.synopsisTitle : '작품명'}] 펀딩 금액</p>
                                    </div>
                                    <p className="ld-brd-price c-orange">
                                        {item ? item.leaderAmount.toLocaleString() : 0} ₩
                                    </p>
                                </li>
                            })}
                        </ul>
                        <i className="show-more" onClick={() => showMoreLd(this)}></i>
                    </div>
                </div>
            </div>
            <section id="fundingMain" className="w-100 text-center">
                <content>
                    <section className="funding-list-box w-100" id="ing-prjt">
                        <div className="container">
                            <div className="menu-list-title">
                                <div className='ms-2'>
                                    <p className="renew-main-content c-green">펀딩 중</p>
                                    <p className="renew-sub-content">PROJECT <span className='c-green'>OPEN NOW</span></p>
                                </div>
                                <div className='d-line l-green'></div>
                            </div>
                            <Swiper
                                modules={[Autoplay, Pagination, Navigation]}
                                pagination={{ clickable: true }}
                                slidesPerView={2}
                                navigation
                                loop={false}
                                className='swiper-container funding-list'
                            >
                                {resultList && resultList.map((item, index) => {
                                    var genres = item.fundingData && item.fundingData.Genres ? item.fundingData.Genres.split('#') : item.genres.split(',');
                                    if (item.showBtnText == '프로젝트 더 알아보기') {
                                        return <SwiperSlide>
                                            <li className="funding-item">
                                                <div className="left-item-text"><i className='icon-clock'></i> {item.leftDate} days left</div>
                                                <div className="img-box" onClick={() => (!item.showBtn ? buyFundingTicket('none') : buyFundingTicket(item.pollingResultId))}>
                                                    <img src={item.fundingData ? item.fundingData.IntroMobImageLink3 : '/img/renew-img/project1.svg'} style={{ objectFit: "contain" }}></img>
                                                </div>
                                                <div className="content-box" onClick={() => (!item.showBtn ? buyFundingTicket('none') : buyFundingTicket(item.pollingResultId))}>
                                                    <span className="title" data-id={item.pollingResultId}>{item.fundingData ? item.fundingData.SynopsisTitle : item.synopsisTitle}</span>
                                                    <div className="writer-name d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <span className={`percentage-text ` + item.perClass}>{item.percentage ?? 0} % </span>
                                                            <span className="status-text">Funded</span>
                                                        </div>
                                                        <span className='badge-item bg-funding'>funding</span>
                                                    </div>
                                                    <progress id={`progress-ing-` + index} className={item.perClass} value={item.percentage ?? 0} min="0" max="100"></progress>
                                                </div>
                                            </li>
                                        </SwiperSlide>
                                    }
                                })}
                            </Swiper>
                        </div>
                    </section>
                    <section className="funding-list-box w-100" id="soon-prjt">
                        <div className="container">
                            <div className="menu-list-title">
                                <div className='ms-2'>
                                    <p className="renew-main-content c-orange">펀딩 예정</p>
                                    <p className="renew-sub-content">PROJECTS <span className="c-orange">COMING SOON</span></p>
                                </div>
                                <div className='d-line l-orange'></div>
                            </div>
                            <Swiper
                                modules={[Autoplay, Pagination, Navigation]}
                                pagination={{ clickable: true }}
                                slidesPerView={2}
                                navigation
                                loop={false}
                                className='swiper-container funding-list'
                            >
                                {resultList && resultList.map((item, index) => {
                                    var genres = item.fundingData && item.fundingData.Genres ? item.fundingData.Genres.split('#') : item.genres.split(',');
                                    if (item.showBtnText == '퍼블릭 세일 오픈 전') {
                                        return <SwiperSlide>
                                            <li className="funding-item">
                                                <div className="img-box" onClick={() => (!item.showBtn ? buyFundingTicket('none') : buyFundingTicket(item.pollingResultId))}>
                                                    <img src={item.fundingData ? item.fundingData.IntroMobImageLink3 : '/img/renew-img/project1.svg'} style={{ objectFit: "contain" }}></img>
                                                </div>
                                                <div className="content-box" onClick={() => (!item.showBtn ? buyFundingTicket('none') : buyFundingTicket(item.pollingResultId))}>
                                                    <span className="title" data-id={item.pollingResultId}>{item.fundingData ? item.fundingData.SynopsisTitle : item.synopsisTitle}</span>
                                                    <p className="writer-name d-flex align-items-center justify-content-between"><div className={item.dayClass+` d-day-text`}><i className="icon-calendar"></i>{item.comeDate ? 'D-' + item.comeDate : 'D-???'} </div><span className="badge-btn bg-orange">Show more</span></p>
                                                </div>
                                            </li>
                                        </SwiperSlide>
                                    }
                                })}
                            </Swiper>
                        </div>
                    </section>
                    <section className="funding-list-box w-100" id="achieved-prjt">
                        <div className="container">
                            <div className="menu-list-title">
                                <div className='ms-2'>
                                    <p className="renew-main-content c-sky-blue">펀딩 완료</p>
                                    <p className="renew-sub-content">PROJECTS <span className="c-blue">CLOSED</span></p>
                                </div>
                                <div className='d-line l-sky-blue'></div>
                            </div>
                            <Swiper
                                modules={[Autoplay, Pagination, Navigation]}
                                pagination={{ clickable: true }}
                                slidesPerView={2}
                                navigation
                                loop={false}
                                className='swiper-container funding-list'
                            >
                                {resultList && resultList.map((item, index) => {
                                    var genres = item.fundingData && item.fundingData.Genres ? item.fundingData.Genres.split('#') : item.genres.split(',');
                                    if (item.showBtnText == '퍼블릭 세일 마감완료') {
                                        return <SwiperSlide>
                                            <li className="funding-item">
                                                <div className="img-box" onClick={() => (!item.showBtn ? buyFundingTicket('none') : buyFundingTicket(item.pollingResultId))}>
                                                    <img src={item.fundingData ? item.fundingData.IntroMobImageLink3 : '/img/renew-img/project1.svg'} style={{ objectFit: "contain" }}></img>
                                                </div>
                                                <div className="content-box" onClick={() => (!item.showBtn ? buyFundingTicket('none') : buyFundingTicket(item.pollingResultId))}>
                                                    <span className="title" data-id={item.pollingResultId}>{item.fundingData ? item.fundingData.SynopsisTitle : item.synopsisTitle}</span>
                                                    <p className="writer-name d-flex align-items-center justify-content-between"><div className="done-text d-flex align-items-center"><i className='icon-done'></i><span className={item.perClass + ` fund-percent`}>100% </span></div><span className="badge-btn bg-sky-blue">Show more</span></p>
                                                </div>
                                            </li>
                                        </SwiperSlide>
                                    }
                                })}
                            </Swiper>
                        </div>
                    </section>
                </content>
            </section>
            <Modal />
            <Footer />
        </>
        )

    } else {
        return (
            <>
                <Head />
                <Header />
                <link rel='stylesheet' href='/css/funding.min.css' />
                <div className="funding title-box d-flex align-items-start justify-content-between">
                    <div className="w-banner">
                        <p className="section-title"><span className="c-red">HOT</span> 프로젝트</p>
                        <Swiper
                            modules={[Autoplay, Pagination, Navigation]}
                            pagination={{ clickable: true }}
                            slidesPerView={"auto"}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false, // 사용자 상호작용시 슬라이더 일시 정지 비활성
                            }}
                            loop={true}
                            className='swiper-container funding-list'>
                            <SwiperSlide>
                                <img src="/img/renew-img/banner/web/banner_1.png"></img>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="/img/renew-img/banner/web/banner_2.png"></img>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="/img/renew-img/banner/web/banner_3.png"></img>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="ld-brd">
                        <p className="section-title"><span className="c-purple">리더</span>보드</p>
                        <div className="ld-brd-list">
                            <ul>
                                {LdList && LdList.map((item, index) => {
                                    return <li className={`ld-brd-item` + (index > 2 ? ` d-none` : ``)}>
                                         <img src={item.thumbnailLink ? item.thumbnailLink :`/img/renew-img/banner/web/ld_test_img.svg`} />
                                        <div className='ld-brd-content'>
                                            <p className="ld-brd-item-nick">{item ? item.nickName : 'XXXXXX'} 님 </p>
                                            <p className="ld-brd-item-title">[{item ? item.synopsisTitle : '작품명'}] 펀딩 금액</p>
                                        </div>
                                        <p className="ld-brd-price c-orange">
                                            {item ? item.leaderAmount.toLocaleString() : 0} ₩
                                        </p>
                                    </li>
                                })}
                            </ul>
                            <i className="show-more" onClick={() => showMoreLd(this)}></i>
                        </div>
                    </div>
                </div>
                <section id="fundingMain" className="w-100 text-center">
                    <content>
                        <div style={{ display: 'flex', justifyContent: 'space-around', cursor: 'pointer' }} id="prjt-tb">
                            <div className="fd-tab-item fd-tab-all active" onClick={() => setActiveTab('all', this)}>전체보기<p className="tab-small-title">ALL PROJECT</p></div>
                            <div className="fd-tab-item fd-tab-ing" onClick={() => setActiveTab('ing', this)}>펀딩 중<p className="tab-small-title">OPEN NOW</p></div>
                            <div className="fd-tab-item fd-tab-soon" onClick={() => setActiveTab('soon', this)}>펀딩 예정<p className="tab-small-title">COMING SOON</p></div>
                            <div className="fd-tab-item fd-tab-done" onClick={() => setActiveTab('done', this)}>펀딩 완료<p className="tab-small-title">CLOSED</p></div>
                        </div>
                        <section className="funding-list-box w-100" id="ing-prjt">
                            <div className="container">
                                <div className="menu-list-title">
                                    <div className='ms-2'>
                                        <p className="renew-main-content c-green">펀딩 중</p>
                                        <p className="renew-sub-content">PROJECT <span className='c-green'>OPEN NOW</span></p>
                                    </div>
                                    <div className='d-line l-green'></div>
                                </div>
                                <Swiper
                                    modules={[Autoplay, Pagination, Navigation]}
                                    pagination={{ clickable: true }}
                                    slidesPerView={4}
                                    navigation
                                    loop={false}
                                    className='swiper-container funding-list'
                                >
                                    {resultList && resultList.map((item, index) => {
                                        var genres = item.fundingData && item.fundingData.Genres ? item.fundingData.Genres.split('#') : item.genres.split(',');
                                        if (item.showBtnText == '프로젝트 더 알아보기') {
                                            return <SwiperSlide>
                                                <li className="funding-item">
                                                    <div className="left-item-text"><i className='icon-clock'></i> {item.leftDate} days left</div>
                                                    <div className="img-box" onClick={() => (!item.showBtn ? buyFundingTicket('none') : buyFundingTicket(item.pollingResultId))}>
                                                        <img src={item.fundingData ? item.fundingData.IntroImageLink3 : '/img/renew-img/project1.svg'} style={{ objectFit: "contain" }}></img>
                                                    </div>
                                                    <div className="content-box" onClick={() => (!item.showBtn ? buyFundingTicket('none') : buyFundingTicket(item.pollingResultId))}>
                                                        <span className="title" data-id={item.pollingResultId}>{item.fundingData ? item.fundingData.SynopsisTitle : item.synopsisTitle}</span>
                                                        <div className="writer-name d-flex align-items-center justify-content-between mb-3">
                                                            <div>
                                                                <span className={`percentage-text ` + item.perClass}>{item.percentage ?? 0} % </span>
                                                                <span className="status-text">Funded</span>
                                                            </div>
                                                            <span className='badge-item bg-funding'>funding</span>
                                                        </div>
                                                        <progress id={`progress-ing-` + index} className={item.perClass} value={item.percentage ?? 0} min="0" max="100"></progress>
                                                    </div>
                                                </li>
                                            </SwiperSlide>
                                        }
                                    })}
                                </Swiper>
                            </div>
                        </section>
                        <section className="funding-list-box w-100" id="soon-prjt">
                            <div className="container">
                                <div className="menu-list-title">
                                    <div className='ms-2'>
                                        <p className="renew-main-content c-orange">펀딩 예정</p>
                                        <p className="renew-sub-content">PROJECTS <span className="c-orange">COMING SOON</span></p>
                                    </div>
                                    <div className='d-line l-orange'></div>
                                </div>
                                <div className="funding-list">
                                    <ul className="d-grid coming-funding-list">
                                        {resultList && resultList.map((item, index) => {
                                            var genres = item.fundingData && item.fundingData.Genres ? item.fundingData.Genres.split('#') : item.genres.split(',');
                                            if (item.showBtnText == '퍼블릭 세일 오픈 전') {
                                                return <li className="funding-item">
                                                    <div className="img-box" onClick={() => (!item.showBtn ? buyFundingTicket('none') : buyFundingTicket(item.pollingResultId))}>
                                                        <img src={item.fundingData ? item.fundingData.IntroImageLink3 : '/img/renew-img/project1.svg'} style={{ objectFit: "contain" }}></img>
                                                    </div>
                                                    <div className="content-box" onClick={() => (!item.showBtn ? buyFundingTicket('none') : buyFundingTicket(item.pollingResultId))}>
                                                        <span className="title" data-id={item.pollingResultId}>{item.fundingData ? item.fundingData.SynopsisTitle : item.synopsisTitle}</span>
                                                        <p className="writer-name d-flex align-items-center justify-content-between"><div className={item.dayClass+` d-day-text`}><i className="icon-calendar"></i>{item.comeDate ? 'D-' + item.comeDate : 'D-???'} </div><span className="badge-btn bg-orange">Show more</span></p>
                                                    </div>
                                                </li>
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </section>
                        <section className="funding-list-box w-100" id="achieved-prjt">
                            <div className="container">
                                <div className="menu-list-title">
                                    <div className='ms-2'>
                                        <p className="renew-main-content c-sky-blue">펀딩 완료</p>
                                        <p className="renew-sub-content">PROJECTS <span className="c-blue">CLOSED</span></p>
                                    </div>
                                    <div className='d-line l-sky-blue'></div>
                                </div>
                                <Swiper
                                    modules={[Autoplay, Pagination, Navigation]}
                                    pagination={{ clickable: true }}
                                    slidesPerView={4}
                                    navigation
                                    loop={false}
                                    className='swiper-container funding-list'
                                >
                                    {resultList && resultList.map((item, index) => {
                                        var genres = item.fundingData && item.fundingData.Genres ? item.fundingData.Genres.split('#') : item.genres.split(',');
                                        if (item.showBtnText == '퍼블릭 세일 마감완료') {
                                            return <SwiperSlide>
                                                <li className="funding-item">
                                                    <div className="img-box" onClick={() => (!item.showBtn ? buyFundingTicket('none') : buyFundingTicket(item.pollingResultId))}>
                                                        <img src={item.fundingData ? item.fundingData.IntroImageLink3 : '/img/renew-img/project1.svg'} style={{ objectFit: "contain" }}></img>
                                                    </div>
                                                    <div className="content-box" onClick={() => (!item.showBtn ? buyFundingTicket('none') : buyFundingTicket(item.pollingResultId))}>
                                                        <span className="title" data-id={item.pollingResultId}>{item.fundingData ? item.fundingData.SynopsisTitle : item.synopsisTitle}</span>
                                                        <p className="writer-name d-flex align-items-center justify-content-between"><div className="done-text d-flex align-items-center"><i className='icon-done'></i><span className={item.perClass + ` fund-percent`}>100% </span> achieved</div><span className="badge-btn bg-sky-blue">Show more</span></p>
                                                    </div>
                                                </li>
                                            </SwiperSlide>
                                        }
                                    })}
                                </Swiper>
                            </div>
                        </section>
                    </content>
                </section>
                <Modal />
                <Footer />
            </>
        )
    }
}
export default Home;